import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row, FormText, Button } from 'reactstrap';
import { FaExchangeAlt, FaTrash } from 'react-icons/fa';
import deepEqual from 'deep-equal';
import chartOfAccounts from 'data/chartOfAccounts.json';
import Constants from 'config/Constants';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesDocument } from 'contexts/DocumentContext';
import { consumesVatCodes, providesVatCodes } from 'contexts/VatCodesContext';
import { providesVat, consumesVat } from 'contexts/VatContext';
import translate from 'containers/translate';
import RuleEdit from 'components/ruleEdit/RuleEdit';
import CurrencyInput from 'components/currencyInput/CurrencyInput';
import DebitorAccountEdit from 'components/debitorAccountEdit/DebitorAccountEdit';
import Currency from 'components/currency/Currency';
import Tooltip from 'components/tooltip/Tooltip';
import AccountingTextAutoSuggest from 'components/accountingTextAutoSuggest/AccountingTextAutoSuggest';
import VatSelect from 'components/vatSelect/VatSelect';
import './AccountingTransaction.scss';

class AccountingTransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyAccountingTransaction: props.accountingTransaction,
      showCreditorAccountEdit: false,
      showDebitorAccountEdit: false,
    };

    this.onAccountClick = this.onAccountClick.bind(this);
    this.onVatSwitch = this.onVatSwitch.bind(this);
    this.onCreditorAccountEditDismiss = this.onCreditorAccountEditDismiss.bind(this);
    this.onDebitorAccountEditDismiss = this.onDebitorAccountEditDismiss.bind(this);
    this.updateTransaction = this.updateTransaction.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
  }

  handleChange(key, value, cb) {
    this.setState(
      (prevState) => {
        return {
          dirtyAccountingTransaction: Object.assign({}, prevState.dirtyAccountingTransaction, { [key]: value }),
        };
      },
      () => cb && cb()
    );
  }

  handleVatChange(value) {
    if (this.isNumeric(value)) {
      this.setVatToState(value, 'NORMAL');
    } else {
      this.setVatToState(0, value);
    }
  }

  setVatToState(vat, vatType) {
    this.setState((prevState) => {
      return {
        dirtyAccountingTransaction: Object.assign({}, prevState.dirtyAccountingTransaction, { vat, vatType }),
      };
    }, this.updateTransaction);
  }

  componentDidUpdate(prevProps) {
    //TODO: remove these comparison and only compare the props that are actually relevant.
    if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
      return;
    }
    if (this.props.documentType !== prevProps.documentType) {
      this.props.remoteVatCodes.api.fetch();
    }
    if (
      this.props.documentDate !== prevProps.documentDate ||
      // When the number of total transactions changes (e.g. one was deleted) we need to fetch vat codes again.
      // This is a total hack but otherwise when inverting a transaction the vat code is still wrong.
      this.props.numTransactions !== prevProps.numTransactions
    ) {
      this.props.remoteVat.api.fetch();
    }
    if (!deepEqual(prevProps.accountingTransaction, this.props.accountingTransaction)) {
      this.setState({
        dirtyAccountingTransaction: this.props.accountingTransaction,
      });
    }
  }

  updateTransaction() {
    if (!deepEqual(this.props.accountingTransaction, this.state.dirtyAccountingTransaction)) {
      this.props.onAccountingTransactionChange(this.state.dirtyAccountingTransaction, this.props.remoteVat.api.fetch);
    }
  }

  onAccountClick() {
    //TODO: maybe turn the logic around and only attach these listeners in render in the first place when locked is false?
    if (!this.props.locked) {
      if (this.props.documentType === 'creditor') {
        this.setState({ showCreditorAccountEdit: true });
      } else {
        this.setState({ showDebitorAccountEdit: true });
      }
    }
  }

  onVatSwitch() {
    const { NORMAL, INVERTED, NONE, VATONLY } = Constants.INVERTED_TYPE;

    const oldType = this.state.dirtyAccountingTransaction.invertedType;
    const id = this.state.dirtyAccountingTransaction.id;
    let invertedType;
    if (this._isNonInvertedTransactionType(oldType)) {
      invertedType = oldType === NONE ? VATONLY : NONE;
    } else {
      invertedType = oldType === NORMAL ? INVERTED : NORMAL;
    }

    this.props.remoteVat.api.update({ id, invertedType }, this.props.remoteDocument.api.fetch);
  }

  onCreditorAccountEditDismiss(account) {
    this.setState({ showCreditorAccountEdit: false });
    this.updateAccount(account);
  }

  onDebitorAccountEditDismiss(account) {
    this.setState({ showDebitorAccountEdit: false });
    this.updateAccount(account);
  }

  updateAccount(account) {
    if (account) {
      const transaction = Object.assign({}, this.state.dirtyAccountingTransaction, { account });
      this.setState({
        dirtyAccountingTransaction: transaction,
      });
      this.props.onAccountingTransactionChange(transaction, this.props.remoteVat.api.fetch);
    }
  }

  renderDebitorAccountEditModal() {
    if (this.state.showDebitorAccountEdit) {
      return (
        <DebitorAccountEdit
          onDismiss={this.onDebitorAccountEditDismiss}
          presetAccount={this.state.dirtyAccountingTransaction.account}
        />
      );
    }
  }

  renderAccountFields() {
    const { invertedType } = this.state.dirtyAccountingTransaction;
    const isInverted = !this._isNonInvertedTransactionType(invertedType);
    if (
      (this.props.documentType === 'creditor' && !isInverted) ||
      (this.props.documentType === 'debitor' && isInverted)
    ) {
      return [this.renderAccountField(), this.renderContraAccountField()];
    } else {
      return [this.renderContraAccountField(), this.renderAccountField()];
    }
  }

  renderAccountField() {
    if (this.props.locked) {
      return (
        <Col key="account-field">
          <Tooltip title={this.getAccountNameFromAccountNumber(this.state.dirtyAccountingTransaction.account)}>
            <FormText tag={'span'} color={'muted'}>
              {this.state.dirtyAccountingTransaction.account}
            </FormText>
          </Tooltip>
        </Col>
      );
    }

    return (
      <Col key="account-field">
        <Tooltip title={this.getAccountNameFromAccountNumber(this.state.dirtyAccountingTransaction.account)}>
          <Button
            color="link"
            className={`account`}
            onClick={this.onAccountClick}
            onFocus={this.onAccountClick}
            tabIndex={0}
          >
            {this.state.dirtyAccountingTransaction.account}
          </Button>
        </Tooltip>
      </Col>
    );
  }

  getAccountNameFromAccountNumber(accountNumber) {
    const accounts = chartOfAccounts[this.props.language];
    const account = accounts.find((a) => a.value === accountNumber);
    if (!account) {
      return '';
    }

    return account.label;
  }

  renderContraAccountField() {
    return (
      <Col key="contra-account-field">
        <Tooltip title={this.getAccountNameFromAccountNumber(this.props.contraAccount)}>
          <FormText color={'muted'} tag={'span'}>
            {this.props.contraAccount}
          </FormText>
        </Tooltip>
      </Col>
    );
  }

  getVatAmount() {
    const vat = this.state.dirtyAccountingTransaction.vat;
    const amount = this.state.dirtyAccountingTransaction.amount;

    if (vat === 10000) {
      return amount;
    }

    const vatAmount = (amount / (10000 + vat)) * vat;
    return Math.round(vatAmount);
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _isNonInvertedTransactionType(type) {
    return [Constants.INVERTED_TYPE.NONE, Constants.INVERTED_TYPE.VATONLY].includes(type);
  }

  isInvertibleVatCode(vatType, vat) {
    const invertible = vatType === 'NORMAL';
    return invertible && vat !== 0 && vat !== 10000;
  }

  renderRuleEditModal() {
    if (!this.state.showCreditorAccountEdit) {
      return null;
    }
    return (
      <RuleEdit
        documentId={this.props.documentId}
        companyName={this.props.companyName}
        presetAccount={this.props.accountingTransaction.account}
        onDismiss={this.onCreditorAccountEditDismiss}
      />
    );
  }

  renderAccountingText() {
    if (!this.props.individualAccountingTexts) {
      return;
    }

    return (
      <AccountingTextAutoSuggest
        presetAccountingText={this.state.dirtyAccountingTransaction.accountingText || ''}
        onAccountingTextChange={(accountingText) =>
          this.handleChange('accountingText', accountingText, this.updateTransaction)
        }
        disabled={this.props.locked}
      />
    );
  }

  renderVatAmount() {
    const { selectedPeriod } = this.props;

    if (selectedPeriod.vatType === 'none') {
      return null;
    }

    return <Currency currency={this.props.currency} value={this.getVatAmount()} />;
  }

  renderVatCode() {
    const { t } = this.props;

    const { vat, vatType } = this.state.dirtyAccountingTransaction;
    let buttonDisabled = this.props.locked;
    buttonDisabled = buttonDisabled || !this.isInvertibleVatCode(vatType, vat);
    let text;

    if (!this.props.vatCode || this.props.vatCode.vatCode.length === 0) {
      text = t('vat_none');
    } else {
      text = this.props.vatCode.vatCode;
    }

    return this.renderVatCodeSwitchButton(text, buttonDisabled);
  }

  renderVatCodeSwitchButton(text, disabled) {
    return (
      <Button color="link" onClick={this.onVatSwitch} className={'vatCode'} disabled={disabled}>
        {text}
      </Button>
    );
  }

  renderVatOnlyDescription() {
    const { t } = this.props;

    if (!this.props.vatCode) {
      return null;
    }

    const { hasWarning } = this.props.vatCode;
    if (!hasWarning) {
      return null;
    }
    return <span className={'reductionIndicator'}>{t('reduction')}</span>;
  }

  renderTransactionInvertIcon() {
    const { t } = this.props;
    const { NONE, INVERTED } = Constants.INVERTED_TYPE;

    if (this.props.locked) {
      return null;
    }

    const { invertedType } = this.state.dirtyAccountingTransaction;
    const isInverted = !this._isNonInvertedTransactionType(invertedType);
    const opposite = isInverted ? NONE : INVERTED;

    return (
      <div className={'transactionInvertedIcon'}>
        <Tooltip title={t('invert_credit_debit')}>
          <span>
            <FaExchangeAlt
              className={classnames({ isInverted })}
              onClick={() => this.handleChange('invertedType', opposite, this.updateTransaction)}
            />
          </span>
        </Tooltip>
        {this.renderVatOnlyDescription()}
      </div>
    );
  }

  renderTrashIcon() {
    if (this.props.locked || !this.props.deletable) {
      return null;
    }
    return <FaTrash onClick={() => this.props.onRemoveAccountingTransaction()} />;
  }

  render() {
    return (
      <tr className={'AccountingTransaction'}>
        {this.renderRuleEditModal()}
        {this.renderDebitorAccountEditModal()}
        <td>
          <CurrencyInput
            value={this.state.dirtyAccountingTransaction.amount}
            onInputChange={(value) => this.handleChange('amount', value, this.updateTransaction)}
            unit={this.props.currency}
            disabled={this.props.locked}
          />
        </td>
        <td>{this.renderAccountingText()}</td>
        <td>
          <VatSelect
            locked={this.props.locked}
            dossier={this.props.dossier}
            onVatChange={this.handleVatChange}
            presetVat={this.state.dirtyAccountingTransaction.vat}
            presetVatType={this.state.dirtyAccountingTransaction.vatType}
          />
        </td>
        <td>{this.renderVatAmount()}</td>
        <td>
          <Row>{this.renderAccountFields()}</Row>
        </td>
        <td className={'vatColumn'}>
          {this.renderVatCode()}
          {this.renderTransactionInvertIcon()}
        </td>
        <td>{this.renderTrashIcon()}</td>
      </tr>
    );
  }
}

AccountingTransaction.defaultProps = {
  deletable: true,
};

AccountingTransaction.propTypes = {
  dossier: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.object.isRequired,
  documentId: PropTypes.number.isRequired,
  documentType: PropTypes.string.isRequired,
  documentDate: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  contraAccount: PropTypes.string.isRequired,
  vatCode: PropTypes.object,
  vatCodes: PropTypes.array,
  accountingTransaction: PropTypes.object.isRequired,
  onAccountingTransactionChange: PropTypes.func.isRequired,
  onRemoveAccountingTransaction: PropTypes.func.isRequired,
  remoteVat: RemoteDataPropType,
  remoteVatCodes: RemoteDataPropType,
  remoteDocument: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  deletable: PropTypes.bool.isRequired,
  individualAccountingTexts: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  numTransactions: PropTypes.number.isRequired,
};

export default consumesDossier(
  consumesDocument(
    providesVatCodes(consumesPeriods(consumesVatCodes(providesVat(consumesVat(translate(AccountingTransaction))))))
  )
);
